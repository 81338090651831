export default [
  {
    first_name: 'dfvdfv',
    last_name: "Tiger Nixon",
    stage: "System Architect",
    opened_app: "Edinburgh",
    food: "61",
    weight: "2011/04/25"
  },
  {
    first_name: 'dfvdfv',
    last_name: "Tiger Nixon",
    stage: "System Architect",
    opened_app: "Edinburgh",
    food: "61",
    weight: "2011/04/25"
  },
  {
    first_name: 'dfvdfv',
    last_name: "Tiger Nixon",
    stage: "System Architect",
    opened_app: "Edinburgh",
    food: "61",
    weight: "2011/04/25"
  }
]
